import React from 'react'
import {graphql} from 'gatsby'
import moment from 'moment'
import Layout from "../../components/layout"
import Footer from "../../components/footer"
import Header from "../../components/header"
import Content from "../../components/content"
import DocumentCard from "../../components/DocumentCard"
import SEO from "../../components/seo"

class DocumentPageTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.agendas = props.data.graphAPI.agendas
    this.minutes = props.data.graphAPI.minutes
    this.documents = props.data.graphAPI.documents
    this.documentsDate = props.data.graphAPI.documentsDate
    this.documentsAZ = props.data.graphAPI.documentsAZ
    this.meta = props.data.graphAPI.contentDocument.meta
    this.content = props.data.graphAPI.contentDocument.content
    
    this.agendasRange = this.getYearRange(this.agendas)
    this.minutesRange = this.getYearRange(this.minutes)

    this.state = {
      navSet: "Agendas",
      sort: "Date",
      agendasYear: this.agendasRange.max,
      minutesYear: this.minutesRange.max
    }

    this.YearNav = this.YearNav.bind(this)
    this.setYear = this.setYear.bind(this)
    this.getCards = this.getCards.bind(this)
    this.setSort = this.setSort.bind(this)
  }

  setNav(val) {
    if(val) {
      this.setState({ navSet: val})
    }
  }

  setYear(year, type) {
    if(type === "Agendas") {
      this.setState({ agendasYear: year})
    } else if( type === "Minutes") {
      this.setState({ minutesYear: year})
    }
  }

  setSort(sortto) {
      this.setState({ sort: sortto})
  }
  getYearRange(array) {
    let min = new Date(array[0].meetingTime).getFullYear()
    let max = 0

    array.map( x => {
      let year = new Date(x.meetingTime).getFullYear()
      min = year < min ? year : min
      max = year > max ? year : max
    })

    return { min: min, max: max }
  }

  YearNav({set}) {
    let yearset = [ ];

    if(set === "Agendas") {
      for(let i = this.agendasRange.max; i >= this.agendasRange.min; i--) {
          yearset.push(i)
      }
    return(<div style={{marginBottom: '1rem'}}>
        <p>Select Year</p>
        <div className="buttons is-centered">
        {yearset.map(year => (
          <button 
            onClick={() => this.setYear(year, set)}
            className={'button '+( this.state.agendasYear == year ? 'is-primary' : '')}>
            {year}
          </button>
        ))}
        </div>
      </div>)
    }
    else if(set === "Minutes") {
      for(let i = this.minutesRange.max; i >= this.minutesRange.min; i--) {
          yearset.push(i)
      }
    return(<div style={{marginBottom: '1rem'}}>
        <p>Select Year</p>
        <div className="buttons is-centered">
        {yearset.map(year => (
          <button 
            onClick={() => this.setYear(year, set)}
            className={'button '+( this.state.minutesYear == year ? 'is-primary' : '')}>
            {year}
          </button>
        ))}
        </div>
      </div>)
    } else {
      return(
        <div style={{marginBottom: '1rem'}}>
          <p>Sort By</p>
          <div className="buttons is-centered">
            <button onClick={() => this.setSort('Date')} className={'button '+( this.state.sort == 'Date' ? 'is-primary' : '')}>Date Published</button>
            <button onClick={() => this.setSort('A-Z')} className={'button '+( this.state.sort == 'A-Z' ? 'is-primary' : '')}>A-Z</button>
          </div>
        </div>
      )
    }
    
  }

  getCards() {
    if( this.state.navSet === "Agendas") {
      return (
        <>
        {this.agendas
          .filter(agenda => new Date(agenda.meetingTime).getFullYear() === this.state.agendasYear )
          .map(agenda =>(
          <DocumentCard 
            key={agenda.id}
            title={`${agenda.meetingType} Meeting Agenda`}
            subtitle={moment(agenda.meetingTime).format("lll")}
            rootLink={agenda.document.documentFile.publicURL}
            rootLinkName={`View Agenda`}
            rootLinkSize={agenda.document.documentFile.prettySize}
            attachments={agenda.attachments}
          />
        ))}
        </>
      )
    }else if( this.state.navSet === "Minutes") {
      return (
        <>
        {this.minutes
          .filter(minutes => new Date(minutes.meetingTime).getFullYear() === this.state.minutesYear )
          .map(minutes =>(
          <DocumentCard 
            key={minutes.id}
            title={`${minutes.meetingType} Meeting Minutes`}
            subtitle={moment(minutes.meetingTime).format("ll")}
            rootLink={minutes.document.documentFile.publicURL}
            rootLinkName={`View Minutes`}
            rootLinkSize={minutes.document.documentFile.prettySize}
            attachments={minutes.attachments}
          />
        ))}
        </>
      )

    } else {
      if (this.state.sort === 'Date') {
        return (
          <>
          { this.documentsDate.map(document =>(
            <div key={document.id} className="document-grid-item">
              <div className="card" style={{marginBottom: '1rem'}}>
                <div className="card-content">
                  <p className="is-size-6">{document.title}<br />
                  <small className="has-text-grey">{moment(document.publishedAt).format("ll")}</small>
                  </p>
                </div>
                <div className="card-footer">
                  <a href={document.document.documentFile.publicURL} target="_blank" className="card-footer-item">View Document</a>
                  <div className="card-footer-item">{document.document.documentFile.prettySize}</div>
                </div>
                { document.attachments.length > 0 ? (
                  <>{document.attachments.map(attachment => (
                    <div key={attachment.id} className="card-footer">
                      <a href={attachment.document.documentFile.publicURL} target="_blank" className="card-footer-item">{attachment.title}</a>
                      <div className="card-footer-item">{attachment.document.documentFile.prettySize}</div>
                    </div>
                  ))}</>
                ) : (<></>)}
              </div>
            </div>
          ))}
          </>
        )
      } else {
        return (
          <>
          { this.documentsAZ.map(document =>(
            <div key={document.id} className="document-grid-item">
              <div className="card" style={{marginBottom: '1rem'}}>
                <div className="card-content">
                  <p className="is-size-6">{document.title}<br />
                  <small className="has-text-grey">{moment(document.publishedAt).format("ll")}</small>
                  </p>
                </div>
                <div className="card-footer">
                  <a href={document.document.documentFile.publicURL} target="_blank" className="card-footer-item">View Document</a>
                  <div className="card-footer-item">{document.document.documentFile.prettySize}</div>
                </div>
                { document.attachments.length > 0 ? (
                  <>{document.attachments.map(attachment => (
                    <div key={attachment.id} className="card-footer">
                      <a href={attachment.document.documentFile.publicURL} target="_blank" className="card-footer-item">{attachment.title}</a>
                      <div className="card-footer-item">{attachment.document.documentFile.prettySize}</div>
                    </div>
                  ))}</>
                ) : (<></>)}
              </div>
            </div>
          ))}
          </>
        )
      }
    }
  }

  render() {


  return (

        <Layout>
            <SEO title="Documents" />
            <div className="main">
                <Header  />
                <div className="main-content-container">
                <div className="main-content">
                    <div className="title-section">
                        <h1 className="title is-size-1">Documents</h1>
                    </div>
                    <Content content={this.content} />

                    <div className="content-section" style={{ maxWidth: '100%'}}>

        <div className="tabs is-centered">
          <ul>
            <li onClick={ () => this.setNav("Agendas") }
              className={this.state.navSet === "Agendas" ? "is-active" : "" } >
              <a>Agendas</a>
            </li>
            <li onClick={ () => this.setNav("Minutes") }
              className={this.state.navSet === "Minutes" ? "is-active" : "" }> 
              <a>Minutes</a>
            </li>
            <li onClick={ () => this.setNav("Documents") }
              className={this.state.navSet === "Documents" ? "is-active" : "" }>
              <a>Documents</a>
            </li>
          </ul>
        </div>

        <div className="has-text-centered">
          <this.YearNav set={this.state.navSet} />
        </div>

        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="document-grid">
              <this.getCards />
            </div>
          </div>
        </div>
                    </div>
                </div>
                <Footer />
            </div>
            </div>
        </Layout>
  )
  }
} 


export default DocumentPageTemplate


export const documentPageQuery = graphql`
  query documentPageById {
    graphAPI {
        contentDocument {
            meta {
                id
                title
                description
            }
            content {
                ... on GRAPHAPI_ComponentPageBaseContent {
                    ...baseContent
                }
                ... on GRAPHAPI_ComponentPageImage {
                    ...pageImage
                }
                ... on GRAPHAPI_ComponentPageLinkCardWithImage {
                    ...linkCardWithImage
                }
                ... on GRAPHAPI_ComponentPageSplitContentImage {
                    ...splitContentImage
                }
                ... on GRAPHAPI_ComponentPageGallery {
                    ...pageGallery
                }
                ... on GRAPHAPI_ComponentPageLinkList {
                    ...pageLinkList
                }
            }
        }
        agendas(sort: "meetingTime:desc") {
          meetingTime
          meetingType
          document {
            ...publicDocument
          }
          attachments {
            title
            id
            document {
              ...publicDocument
            }
          }
        }
        documentsDate: documents(sort: "publishedAt:desc") {
          title
          publishedAt
          document {
            ...publicDocument
          }
          attachments {
            title
            id
            document {
              ...publicDocument
            }
          }
        }
        documentsAZ: documents(sort: "title:asc") {
          title
          publishedAt
          document {
            ...publicDocument
          }
          attachments {
            title
            id
            document {
              ...publicDocument
            }
          }
        }
        minutes(sort: "meetingTime:desc") {
          meetingTime
          meetingType
          document {
            ...publicDocument
          }
          attachments {
            title
            id
            document {
              ...publicDocument
            }
          }
        }
      }
    }
`